<template>
  <header class="content-header d-flex justify-content-between">
    <h1>{{ title }}</h1>
  </header>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
export default class MainTitleComponent extends Vue {}
</script>

<template>
  <header-component />
  <MainContentComponent>
    <MainTitleComponent title="Plateforme d'affiliation" />
    <component :is="template" v-if="template !== ''" />
  </MainContentComponent>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { defineAsyncComponent } from 'vue'

import MainTitleComponent from '@/components/DataDisplay/MainTitle/MainTitle.vue'
import MainContentComponent from '@/components/DataDisplay/MainContent/MainContent.vue'
// eslint-disable-next-line
import { IUserInfo } from '@/intefaces/login'
import HeaderComponent from '@/components/DataDisplay/Header/Header.vue'
import { mapActions, mapGetters } from 'vuex'

interface Data {
  view: string
}

const DilypseAffiliation = defineAsyncComponent(
  () => import('./DilypseAffiliation/DilypseAffiliation.vue')
)
const DilypseReseller = defineAsyncComponent(
  () => import('./DilypseReseller/DilypseReseller.vue')
)

@Options({
  name: 'DilypseDashboard',
  components: {
    MainTitleComponent,
    MainContentComponent,
    DilypseAffiliation,
    DilypseReseller,
    HeaderComponent,
  },
  data(): Data {
    return {
      view: 'DilypseReseller',
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    template() {
      return this.getUserInfo
        ? this.getUserInfo.affiliate_id
          ? 'DilypseAffiliation'
          : 'DilypseReseller'
        : ''
    },
  },
  mounted() {
    this.setUserInfo()
  },
  methods: {
    ...mapActions(['setUserInfo']),
  },
})
export default class DilypseDashboard extends Vue {}
</script>

<style lang="scss">
.td-footer-info {
  background: #0062ff;
  border-radius: 30px;
  padding: 2px 8px;
  color: white;
  display: inline-block;
}
</style>

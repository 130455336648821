
import { Options, Vue } from 'vue-class-component'
import localforage from 'localforage'

import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'

@Options({
  computed: {
    ...mapGetters(['getUserInfo']),
    userInfo() {
      return this.getUserInfo || { user: {} }
    },
    avatarName() {
      const { firstname, lastname } = this.userInfo
      return this.getFirstLetter(firstname) + this.getFirstLetter(lastname)
    },
  },
  mounted() {
    this.setUserInfo()
  },
  methods: {
    ...mapActions(['setUserInfo']),
    async handleLogout() {
      await localforage.removeItem('userInfo')
      this.setUserInfo()
      router.push('/login')
    },
    getFirstLetter(str: string) {
      if (str) {
        return str.charAt(0)
      }
      return ''
    },
  },
})
export default class HeaderComponent extends Vue {}

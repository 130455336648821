<template>
  <nav class="navbar">
    <div class="img-space flex-grow-1">
      <img
        :src="require(`@/assets/dilypse.svg`)"
        width="40"
        height="40"
        alt=""
      />
    </div>
    <div id="profile-name" class="profil-name">
      <span>
        <span class="avatar-wrapper">
          <span class="avatar">
            {{ avatarName }}
          </span>
          <span class="d-flex d-md-inline-flex user-info-name">
            {{ userInfo.firstname }} {{ userInfo.lastname }}
            <i
              class="power ml-2"
              title="Déconnexion"
              @click="handleLogout"
              style="cursor: pointer"
            ></i>
          </span>
        </span>
      </span>
    </div>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import localforage from 'localforage'

import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'

@Options({
  computed: {
    ...mapGetters(['getUserInfo']),
    userInfo() {
      return this.getUserInfo || { user: {} }
    },
    avatarName() {
      const { firstname, lastname } = this.userInfo
      return this.getFirstLetter(firstname) + this.getFirstLetter(lastname)
    },
  },
  mounted() {
    this.setUserInfo()
  },
  methods: {
    ...mapActions(['setUserInfo']),
    async handleLogout() {
      await localforage.removeItem('userInfo')
      this.setUserInfo()
      router.push('/login')
    },
    getFirstLetter(str: string) {
      if (str) {
        return str.charAt(0)
      }
      return ''
    },
  },
})
export default class HeaderComponent extends Vue {}
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  align-items: center;
}
.user-info-name {
  flex-grow: 1;
  white-space: nowrap;
  align-items: center;
}
.avatar {
  flex: 0 0 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #4b45b2;
  color: #fff;
  margin-right: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
</style>

<template>
  <div class="main-content">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class MainContentComponent extends Vue {}
</script>
